<template>
    <component v-bind="linkProps(to, target)">
        <slot></slot>
    </component>
</template>

<script>
    export default {
        props: {
            to: {
                type: String,
                required: true
            },
            target: {
                type: String,
                required: false
            }
        },
        methods: {
            linkProps( url, target ) {
                if ( url.match( /^(http(s)?):\/\// ) ) {
                    return {
                        is: 'a',
                        href: url,
                        target: target ? target : '',
                        rel: 'noopener noreferrer'
                    }
                }
                return {
                    is: 'nuxt-link',
                    to: url,
                    target: target ? target : '',
                }
            }
        }
    }
</script>
