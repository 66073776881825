<template>
    <div>
        <header class="header" :class="'header--' + backgroundColor + ' header--' + positionType">
            <div class="container">
                <div class="frame">
                    <div class="bit-xxs-12 header__inner flex flex--wrap flex--justify-space-between flex--align-center">
                        <h1 class="site__brand" v-if="path === '/' || path === '/en/'">
                            <HeaderImage :background-color="backgroundColor"/>
                        </h1>
                        <div class="site__brand" v-else>
                            <HeaderImage :background-color="backgroundColor"/>
                        </div>

                        <div class="header__menu flex flex--wrap flex--align-center">
                            <Menu :background-color="backgroundColor"/>
                            <div class="lang-switcher__container hidden-mobile hidden-tablet">
                                <LangSwitcher/>
                            </div>
                            <a href="javascript:;" class="menu-trigger" :class="{'menu-trigger--green': (backgroundColor === 'white' || backgroundColor === 'gray')}" @click.prevent="toggleMenu" aria-label="Open Mobiel Menu">
                                <span class="menu-trigger__icon-bar"></span>
                            </a>
                        </div>
                    </div>
                </div>
            </div>
        </header>
        <MobileMenu :button-color="backgroundColor"/>
    </div>
</template>

<script>
    import { mapState } from 'vuex';
    import MobileMenu from './menu-mobile';
    import Menu from './menu';
    import LangSwitcher from "./lang-switcher";
    import HeaderImage from "./header-image";

    export default {
        name: 'Header',
        components: {
            HeaderImage,
            LangSwitcher,
            MobileMenu,
            Menu,
        },
        props: {
            backgroundColor: {
                type: String,
                default: 'white',
                validator: function ( value ) {
                    // White results in header with white background and coloured logo + menu
                    // Gray results in header with transparent background and coloured logo + menu
                    // Blue and Transparent results in header with transparent background and white logo + menu
                    return [ 'white', 'transparent', 'blue', 'gray' ].indexOf( value ) !== -1;
                }
            },
            positionType: {
                type: String,
                default: 'relative',
                validator: function ( value ) {
                    return [ 'relative', 'absolute', ].indexOf( value ) !== -1;
                }
            },
        },
        data() {
            return {}
        },
        computed: {
            ...mapState( {
                mobile_menu_open: state => state.menus.mobile_menu_open,
                path: function () {
                    return this.$route.path;
                },
            } ),
        },
        methods: {
            toggleMenu() {
                this.$store.commit( 'menus/toggleMobileMenu', !this.mobile_menu_open );
            }
        },
    }
</script>
