<template>
    <nav v-if="menu" class="main-menu" role="navigation" aria-label="Main Menu" :class="backgroundColor === 'blue' || backgroundColor === 'transparent' ? 'main-menu--white' : ''">
        <div class="main-menu__wrapper hidden-mobile hidden-tablet ">
            <ul :id="menu.term_id" class="menu menu--main menu--submenu" :class="'menu--' + menu.slug" v-if="menu.items">
                <li v-for="item in menu.items" class="menu-item" :class="[(item.child_items ? 'menu-item-has-children' : ''), (addMenuClasses(item))]">
                    <nuxt-link :to="item.url" :title="item.title" :target="item.target"><span v-html="item.title"></span></nuxt-link>
                    <ul class="sub-menu" v-if="item.child_items">
                        <li v-for="child_item in item.child_items" class="menu-item">
                            <nuxt-link :to="child_item.url" :title="child_item.title" :target="item.target"><span v-html="child_item.title"></span></nuxt-link>
                        </li>
                    </ul>
                </li>
            </ul>
        </div>
    </nav>
</template>

<script>
    import { mapState } from 'vuex';

    export default {
        name: 'Menu',
        props: {
            backgroundColor: {
                type: String,
                default: 'white',
                validator: function ( value ) {
                    // White results in header with white background and coloured logo + menu
                    // Gray results in header with transparent background and coloured logo + menu
                    // Blue and Transparent results in header with transparent background and white logo + menu
                    return [ 'white', 'transparent', 'blue', 'gray' ].indexOf( value ) !== -1;
                }
            },
        },
        computed: {
            ...mapState( {
                menu: function ( state ) {
                    const object = state.menus.main_menu.find( d => d.locale === this.$i18n.locale );

                    if ( object && object.menu_items ) {
                        return object.menu_items;
                    }
                },
            } ),
        },
        methods: {
            addMenuClasses( item ) {
                if ( item.classes ) {
                    return item.classes.join( ' ' );
                }
            },
        }
    }
</script>

<style scoped>

</style>
