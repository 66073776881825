export const state = () => ({
    pages: [],
    postsPage: null,
    contactPage: [],
    servicePage: [],
    teamPage: [],
    articlesPage: [],
});

export const mutations = {
    setPage( state, data ) {
        if ( state.pages.pages && state.pages.pages.find( p => p.page === data.page ) ) {
            const page = state.pages.pages.find( p => p.page === data.page );

            page.languages.push( {
                locale: data.locale,
                data: data.data,
            } );
        } else {
            state.pages.push( {
                page: data.page,
                languages: [ {
                    locale: data.locale,
                    data: data.data,
                } ],
            } );
        }
    },
    setContactPage( state, data ) {
        state.contactPage.push( {
            locale: data.locale,
            data: data.data,
        } );
    },
    setServicePage( state, data ) {
        state.servicePage.push( {
            locale: data.locale,
            data: data.data,
        } );
    },
    setTeamPage( state, data ) {
        state.teamPage.push( {
            locale: data.locale,
            data: data.data,
        } );
    },
    setArticlesPage( state, data ) {
        state.articlesPage.push( {
            locale: data.locale,
            data: data.data,
        } );
    },
};

export const actions = {
    async getPage( { commit }, data ) {
        return new Promise( ( resolve, reject ) => {
            this.$axios.get( `/wp/v2/pages?slug=${data.params.page}&lang=${data.locale}` )
            .then( ( res ) => {
                if ( /20[0-9]/.exec(res.status) !== null ) {
                    if ( res.data && res.data.length > 0 ) {
                        commit( 'setPage', { page: data.params.page, locale: data.locale, data: res.data[ 0 ] } );

                        let alternateRoute = {};

                        alternateRoute[ res.data[ 0 ].language_information.language_code ] = res.data[ 0 ].link;

                        if ( !_.isEmpty( res.data[ 0 ].alternates ) ) {
                            _.each( res.data.alternates, a => {
                                commit( 'setPage', { locale: a.language_information.language_code, data: a } );
                                alternateRoute[ a.language_information.language_code ] = a.link;
                            } );
                        }

                        resolve( {
                            alternateRoute: alternateRoute,
                        } );
                    } else {
                        console.error( { statusCode: 404, message: 'Page not found' } );
                        reject( 'No results from API' );
                    }
                } else {
                    console.error( { statusCode: 404, message: 'Page not found' } );
                    reject( 'Wrong status from API: ' + res.status );
                }
            } )
            .catch( ( e ) => {
                console.error( e );
                reject( e );
            } );
        } );
    },
    async getContactPage( { commit }, data ) {
        return new Promise( ( resolve, reject ) => {
            this.$axios.get( `/wp/v2/contactpage?lang=${data.locale}` )
            .then( ( res ) => {
                if ( /20[0-9]/.exec(res.status) !== null ) {
                    commit( 'setContactPage', { locale: data.locale, data: res.data } );

                    let alternateRoute                                            = {};
                    alternateRoute[ res.data.language_information.language_code ] = res.data.link;

                    if ( !_.isEmpty( res.data.alternates ) ) {
                        _.each( res.data.alternates, a => {
                            commit( 'setContactPage', { locale: a.language_information.language_code, data: a } );
                            alternateRoute[ a.language_information.language_code ] = a.link;
                        } );
                    }

                    resolve( {
                        alternateRoute: alternateRoute,
                    } );
                } else {
                    reject( 'Wrong status from API: ' + res.status );
                }
            } )
            .catch( ( e ) => {
                console.error( e );
                reject( e );
            } );
        } );
    },
    async getServicePage( { commit }, data ) {
        return new Promise( ( resolve, reject ) => {
            this.$axios.get( `/wp/v2/servicepage?lang=${data.locale}` )
            .then( ( res ) => {
                if ( /20[0-9]/.exec(res.status) !== null ) {
                    commit( 'setServicePage', { locale: data.locale, data: res.data } );

                    let alternateRoute                                            = {};
                    alternateRoute[ res.data.language_information.language_code ] = res.data.link;

                    if ( !_.isEmpty( res.data.alternates ) ) {
                        _.each( res.data.alternates, a => {
                            commit( 'setServicePage', { locale: a.language_information.language_code, data: a } );
                            alternateRoute[ a.language_information.language_code ] = a.link;
                        } );
                    }

                    resolve( {
                        alternateRoute: alternateRoute,
                    } );
                } else {
                    reject( 'Wrong status from API: ' + res.status );
                }
            } )
            .catch( ( e ) => {
                console.error( e );
                reject( e );
            } );
        } );
    },
    async getTeamPage( { commit, dispatch }, data ) {
        return new Promise( ( resolve, reject ) => {
            this.$axios.get( `/wp/v2/teampage?lang=${data.locale}` )
            .then( ( res ) => {
                if ( /20[0-9]/.exec(res.status) !== null ) {
                    commit( 'setTeamPage', { locale: data.locale, data: res.data } );

                    dispatch( 'team/getTeamMembers', data, { root: true } ).then( () => {
                        let alternateRoute                                            = {};
                        alternateRoute[ res.data.language_information.language_code ] = res.data.link;

                        if ( !_.isEmpty( res.data.alternates ) ) {
                            _.each( res.data.alternates, a => {
                                commit( 'setTeamPage', { locale: a.language_information.language_code, data: a } );
                                alternateRoute[ a.language_information.language_code ] = a.link;
                            } );
                        }

                        resolve( {
                            alternateRoute: alternateRoute,
                        } );
                    } )
                    .catch( ( e ) => {
                        console.error( e );
                        reject( e );
                    } );
                } else {
                    reject( 'Wrong status from API: ' + res.status );
                }
            } )
            .catch( ( e ) => {
                console.error( e );
                reject( e );
            } );
        } );
    },
    async getArticlesPage( { commit }, data ) {
        return new Promise( ( resolve, reject ) => {
            this.$axios.get( `/wp/v2/articlespage?lang=${data.locale}` )
            .then( ( res ) => {
                if ( /20[0-9]/.exec(res.status) !== null ) {
                    commit( 'setArticlesPage', { locale: data.locale, data: res.data } );

                    let alternateRoute                                            = {};
                    alternateRoute[ res.data.language_information.language_code ] = res.data.link;

                    if ( !_.isEmpty( res.data.alternates ) ) {
                        _.each( res.data.alternates, a => {
                            commit( 'setArticlesPage', { locale: a.language_information.language_code, data: a } );
                            alternateRoute[ a.language_information.language_code ] = a.link;
                        } );
                    }

                    resolve( {
                        alternateRoute: alternateRoute,
                    } );
                } else {
                    reject( 'Wrong status from API: ' + res.status );
                }
            } )
            .catch( ( e ) => {
                console.error( e );
                reject( e );
            } );
        } );
    },
};
