import Vue from 'vue';

const he = require( 'he' );

Vue.mixin( {
    methods: {
        htmlEncode( string ) {
            if ( string && string.length > 0 ) {
                return he.encode( string );
            } else {
                return string;
            }
        },
        htmlDecode( string ) {
            if ( string && string.length > 0 ) {
                return he.decode( string );
            } else {
                return string;
            }
        }
    }
} );
