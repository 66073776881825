import Vue from 'vue';
import anime from 'animejs';

Vue.mixin( {
    methods: {
        scroll( arg ) {
            let el       = document.querySelector( arg.to ),
                offset   = parseInt( arg.offset ) || 0,
                duration = arg.duration || 800,
                easing   = arg.easing || 'easeInOutQuad',
                callback = arg.callback || null;
            
            if ( el ) {
                anime( {
                    targets: [ 'html', 'body' ],
                    scrollTop: (el.offsetTop - offset),
                    duration: duration,
                    easing: easing,
                    complete: callback
                } );
            }
        }
    }
} );
