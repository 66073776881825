export const state = () => ({
    posts: null,
    post: null,
    totalPosts: 0,
});

export const mutations = {
    set( state, data ) {
        state.posts = data;
    },
    setSingle( state, data ) {
        state.post = data;
    },
    addPosts( state, data ) {
        _.forEach( data, function ( p ) {
            state.posts.push( p );
        } );
    },
    setTotalPosts( state, count ) {
        state.totalPosts = count;
    }
};

export const actions = {
    async getPosts( { commit }, params ) {
        await this.$axios.get( `/wp/v2/posts?per_page=${params.count}&offset=${params.offset}` )
        .then( ( res ) => {
            if ( /20[0-9]/.exec(res.status) !== null ) {
                commit( 'set', res.data );

                if ( res.headers && res.headers[ 'x-wp-total' ] ) {
                    commit( 'setTotalPosts', res.headers[ 'x-wp-total' ] );
                }
            }
        } );
    },
    async addPosts( { commit }, params ) {
        await this.$axios.get( `/wp/v2/posts?per_page=${params.count}&offset=${params.offset}` )
        .then( ( res ) => {
            if ( /20[0-9]/.exec(res.status) !== null ) {
                commit( 'addPosts', res.data );
            }
        } );
    },
    async getSingle( { commit }, params ) {
        await this.$axios.get( `/wp/v2/posts?slug=${params.post}` )
        .then( ( res ) => {
            if ( /20[0-9]/.exec(res.status) !== null ) {
                if ( res.data.length ) {
                    commit( 'setSingle', res.data[ 0 ] );
                }
            }
        } );
    },
};
