<template>
    <div class="page-container">
        <main>
            <nuxt/>
        </main>
        <Footer/>
        <span v-if="cookie_consent" v-html="cookie_consent"></span>
    </div>
</template>


<script>
    import Footer from '~/components/footer.vue';
    import { mapState } from 'vuex';

    export default {
        components: {
            Footer
        },
        computed: {
            ...mapState( {
                cookie_consent: state => state.general.general_settings.cookie_consent,
            } ),
        },
    }
</script>
