<template>
    <nuxt-link :to="localePath('index')" :title="blogTitle">
        <img :src="logoImage" :alt="blogTitle" class="img-responsive"/>
    </nuxt-link>
</template>

<script>
    import { mapState } from 'vuex';

    export default {
        name: 'HeaderImage',
        props: {
            backgroundColor: {
                type: String,
                default: 'white',
                validator: function ( value ) {
                    // White results in header with white background and coloured logo + menu
                    // Gray results in header with transparent background and coloured logo + menu
                    // Blue and Transparent results in header with transparent background and white logo + menu
                    return [ 'white', 'transparent', 'blue', 'gray' ].indexOf( value ) !== -1;
                }
            },
        },
        data() {
            return {}
        },
        computed: {
            ...mapState( {
                blogTitle: ( state ) => {
                    return state.general.general_settings && state.general.general_settings.blog_title ? state.general.general_settings.blog_title : 'Stadermann Luiten Advocaten';
                },
            } ),
            logoImage: function () {
                if ( this.backgroundColor === 'white' || this.backgroundColor === 'gray' ) {
                    return require( `@/assets/images/logo-stadermann-luiten-kleur.png` );
                } else {
                    return require( `@/assets/images/logo-stadermann-luiten-wit.png` );
                }
            }
        }
    }
</script>
