import Vue from 'vue'
import Router from 'vue-router'
import { interopDefault } from './utils'
import scrollBehavior from './router.scrollBehavior.js'

const _3ceb8e09 = () => interopDefault(import('../pages/contact/index.vue' /* webpackChunkName: "pages/contact/index" */))
const _28f3010d = () => interopDefault(import('../pages/kennisbank/index.vue' /* webpackChunkName: "pages/kennisbank/index" */))
const _5548ee48 = () => interopDefault(import('../pages/over-ons/index.vue' /* webpackChunkName: "pages/over-ons/index" */))
const _62ac40f5 = () => interopDefault(import('../pages/styleguide.vue' /* webpackChunkName: "pages/styleguide" */))
const _037c53e7 = () => interopDefault(import('../pages/team.vue' /* webpackChunkName: "pages/team" */))
const _2983ddac = () => interopDefault(import('../pages/team/index.vue' /* webpackChunkName: "pages/team/index" */))
const _16925612 = () => interopDefault(import('../pages/team/_id.vue' /* webpackChunkName: "pages/team/_id" */))
const _8f5f9da2 = () => interopDefault(import('../pages/articles/_article.vue' /* webpackChunkName: "pages/articles/_article" */))
const _73cf0c1f = () => interopDefault(import('../pages/kennisbank/_theme.vue' /* webpackChunkName: "pages/kennisbank/_theme" */))
const _4905fb38 = () => interopDefault(import('../pages/index.vue' /* webpackChunkName: "pages/index" */))
const _9c718058 = () => interopDefault(import('../pages/_page.vue' /* webpackChunkName: "pages/_page" */))

Vue.use(Router)

export const routerOptions = {
  mode: 'history',
  base: decodeURI('/'),
  linkActiveClass: 'nuxt-link-active',
  linkExactActiveClass: 'nuxt-link-exact-active',
  scrollBehavior,

  routes: [{
    path: "/contact",
    component: _3ceb8e09,
    name: "contact___nl"
  }, {
    path: "/en/contact",
    component: _3ceb8e09,
    name: "contact___en"
  }, {
    path: "/kennisbank",
    component: _28f3010d,
    name: "kennisbank___nl"
  }, {
    path: "/en/kennisbank",
    component: _28f3010d,
    name: "kennisbank___en"
  }, {
    path: "/over-ons",
    component: _5548ee48,
    name: "over-ons___nl"
  }, {
    path: "/en/about-us",
    component: _5548ee48,
    name: "over-ons___en"
  }, {
    path: "/styleguide",
    component: _62ac40f5,
    name: "styleguide___nl"
  }, {
    path: "/en/styleguide",
    component: _62ac40f5,
    name: "styleguide___en"
  }, {
    path: "/team",
    component: _037c53e7,
    children: [{
      path: "",
      component: _2983ddac,
      name: "team___nl"
    }, {
      path: ":id",
      component: _16925612,
      name: "team-id___nl"
    }]
  }, {
    path: "/en/team",
    component: _037c53e7,
    children: [{
      path: "",
      component: _2983ddac,
      name: "team___en"
    }, {
      path: ":id",
      component: _16925612,
      name: "team-id___en"
    }]
  }, {
    path: "/articles/:article?",
    component: _8f5f9da2,
    name: "articles-article___nl"
  }, {
    path: "/en/articles/:article?",
    component: _8f5f9da2,
    name: "articles-article___en"
  }, {
    path: "/kennisbank/:theme",
    component: _73cf0c1f,
    name: "kennisbank-theme___nl"
  }, {
    path: "/en/kennisbank/:theme",
    component: _73cf0c1f,
    name: "kennisbank-theme___en"
  }, {
    path: "/",
    component: _4905fb38,
    name: "index___nl"
  }, {
    path: "/en/",
    component: _4905fb38,
    name: "index___en"
  }, {
    path: "/:page",
    component: _9c718058,
    name: "page___nl"
  }, {
    path: "/en/:page",
    component: _9c718058,
    name: "page___en"
  }, {
    path: "/actualiteiten/:article?",
    component: _8f5f9da2,
    name: "actualiteit___nl"
  }, {
    path: "/en/actualiteiten/:article?",
    component: _8f5f9da2,
    name: "actualiteit___en"
  }],

  fallback: false
}

export function createRouter () {
  return new Router(routerOptions)
}
