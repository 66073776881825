if ( process.client ) {
    const eventName = 'imagesLoaded';
    let event;
    const img       = document.querySelector( 'img' );
    
    event = document.createEvent( 'Event' );
    event.initEvent( eventName, true, true );
    
    document.addEventListener( eventName, e => {
        const images = document.querySelectorAll( 'img[data-resp-bg-img]' );
        let parent;
        
        for ( let i = 0; i < images.length; i++ ) {
            parent = getClosest( images[ i ], '[data-resp-bg-img-wrapper]' );
            
            if (parent) {
                const currentSrc = parent.style.backgroundImage;
                let src          = (typeof this.currentSrc === 'undefined' || images[ i ].currentSrc === 'none' || images[ i ].currentSrc.length === 0) ? images[ i ].src : this.currentSrc;
                src              = 'url("' + src + '")';
    
                if ( src !== currentSrc ) {
                    parent.setAttribute( 'style', 'background-image: ' + src );
                }
    
                if ( !parent.classList.contains( 'responsivebackgroundimage--loaded' ) ) {
                    parent.classList.add( 'responsivebackgroundimage--loaded' );
                }
            }
        }
    }, false );
    
    window.addEventListener( "resize", () => {
        document.dispatchEvent( event );
    } );
    
    window.addEventListener( "load", () => {
        document.dispatchEvent( event );
    } );
    
    window.onNuxtReady( ( app ) => {
        document.dispatchEvent( event );
    } );
    
    if ( img.complete ) {
        document.dispatchEvent( event );
    } else {
        img.addEventListener( 'load', () => {
            document.dispatchEvent( event );
        } );
    }
    
    // @source: https://gomakethings.com/how-to-get-the-closest-parent-element-with-a-matching-selector-using-vanilla-javascript/
    function getClosest( elem, selector ) {
        
        // Element.matches() polyfill
        if ( !Element.prototype.matches ) {
            Element.prototype.matches =
                Element.prototype.matchesSelector ||
                Element.prototype.mozMatchesSelector ||
                Element.prototype.msMatchesSelector ||
                Element.prototype.oMatchesSelector ||
                Element.prototype.webkitMatchesSelector ||
                function ( s ) {
                    let matches = (this.document || this.ownerDocument).querySelectorAll( s ),
                        i       = matches.length;
                    while ( --i >= 0 && matches.item( i ) !== this ) {}
                    return i > -1;
                };
        }
        
        // Get the closest matching element
        for ( ; elem && elem !== document; elem = elem.parentNode ) {
            if ( elem.matches( selector ) ) return elem;
        }
        return null;
        
    }
}
