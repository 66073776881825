export const state = () => ({});

export const mutations = {};

export const actions = {
    async nuxtServerInit( { commit, dispatch } ) {
        await dispatch( 'menus/getMainMenu' );
        await dispatch( 'menus/getFooterMenu' );
        await dispatch( 'menus/getMobileMenu' );
        await dispatch( 'menus/getSecondaryMenu' );
        await dispatch( 'menus/getDisclaimerMenu' );
        await dispatch( 'general/getGeneralSettings' );
    }
};
