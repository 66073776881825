export const state = () => ({
    data: [],
});

export const mutations = {
    update( state, data ) {
        state.data.push( {
            locale: data.locale,
            data: data.data,
        } );
    },
};

export const actions = {
    async get( { commit }, data ) {
        return new Promise( ( resolve, reject ) => {
            this.$axios.get( `/wp/v2/frontpage?lang=${data.locale}` )
            .then( ( res ) => {
                if ( /20[0-9]/.exec(res.status) !== null && res.data ) {
                    commit( 'update', { locale: data.locale, data: res.data } );

                    let alternateRoute                                            = {};
                    alternateRoute[ res.data.language_information.language_code ] = res.data.link;

                    if ( res.data.alternates ) {
                        _.each( res.data.alternates, a => {
                            commit( 'update', { locale: a.language_information.language_code, data: a } );
                            alternateRoute[ a.language_information.language_code ] = a.link;
                        } );
                    }

                    resolve( {
                        alternateRoute: alternateRoute,
                    } );
                } else {
                    reject( 'Wrong status from API: ' + res.status );
                }
            } )
            .catch( ( e ) => {
                console.error( e );
                reject( e );
            } );
        } );
    },
};
