import Vue from 'vue'

export default ({ app }, inject) => {
    // Set the function directly on the context.app object
    window.enableCookies = function() {
        const videoItems = document.querySelectorAll( '[data-load-video]' );
        
        for ( let i = 0; i < videoItems.length; i++ ) {
            videoItems[i].setAttribute( 'data-fancybox', '' );
        }
    };
    window.hasAllowedCookies = function() {
        return document.cookie.split( ';' ).filter( ( item ) => item.includes( 'cookieconsent_status=allow' ) );
    };
}

Vue.prototype.enableCookies = () => {
    if ( process.client ) {
        if ( window.hasAllowedCookies() && window.hasAllowedCookies().length > 0 ) {
            window.enableCookies();
        }
    }
};
