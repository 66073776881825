<template>
    <div class="lang-switcher">
        <div @click.prevent="showDropDown=!showDropDown" class="lang-switcher__current" :class="{'lang-switcher__current-open': showDropDown}">
            {{currentLocaleInfo.name}} <i class="fal fa-angle-down"></i>
        </div>
        <div class="lang-switcher__dropdown" :class="{ 'lang-switcher__dropdown-open': showDropDown}">
            <nuxt-link
                    class="lang-switcher__link"
                    v-for="locale in $i18n.locales"
                    v-if="locale.code !== $i18n.locale"
                    :key="locale.code"
                    :to="alternateRoute[locale.code]||''"
                    :hreflang="locale.iso"
                    :title="locale.name"
                    rel="alternate"
            >{{ locale.name }}
            </nuxt-link>
        </div>

    </div>
</template>

<script>
    import { mapState } from 'vuex';

    export default {
        name: 'LangSwitcher',
        data() {
            return {
                showDropDown: false,
            }
        },
        computed: {
            ...mapState( {
                alternateRoute: state => state.general.alternateRoute
            } ),
            currentLocaleInfo: function () {
                return this.$i18n.locales.find( l => l.code === this.$i18n.locale );
            }
        },
    }

</script>
