export const state = () => ({
    themeArticles: [],
    articles: [],
    breadcrumb: false,
    searchThemeCounts: [],
});

export const mutations = {
    setSearchThemeCounts( state, data ) {
        state.searchThemeCounts = data;
    },
    setThemeArticles( state, data ) {
        state.themeArticles = [data];
    },
    setArticle( state, data ) {
        if ( state.articles.articles && state.articles.articles.find( p => p.article === data.article ) ) {
            const article = state.articles.articles.find( p => p.article === data.article );

            article.languages.push( {
                locale: data.locale,
                data: data.data,
            } );
        } else {
            state.articles.push( {
                article: data.article,
                languages: [{
                    locale: data.locale,
                    data: data.data,
                }],
            } );
        }
    },
    setBreadcrumb( state, data ) {
        state.breadcrumb = data;
    }
};

export const actions = {
    async getThemeArticles( { commit }, data ) {
        let requestUrl = `/wp/v2/theme-articles?theme=${data.params.theme}&lang=${data.locale}`;

        if ( data.query.type ) {
            requestUrl += `&type=${data.query.type}`;
        }

        if ( data.query.author ) {
            requestUrl += `&author-slug=${data.query.author}`;
        }

        if ( data.query.year ) {
            requestUrl += `&year=${data.query.year}`;
        }

        if ( data.query.page ) {
            requestUrl += `&page=${data.query.page}`;
        }

        if ( data.query.q ) {
            requestUrl += `&q=${data.query.q}`;
        }

        await this.$axios.get( requestUrl )
        .then( ( res ) => {
            if ( /20[0-9]/.exec(res.status) !== null ) {
                if ( res.data && res.data.groups ) {
                    commit( 'setThemeArticles', { locale: data.locale, data: res.data.groups } );

                    if ( res.data.breadcrumbs ) {
                        commit( 'setBreadcrumb', res.data.breadcrumbs );
                    }
                } else {
                    console.error( { statusCode: 404, message: 'No Articles found' } );
                }
            } else {
                console.error( { statusCode: 404, message: 'No Articles found' } );
            }
        } )
        .catch( ( e ) => {
            console.error( e );
            console.error( { statusCode: 404, message: 'No Articles found' } );
        } );
    },
    async getArticle( { commit }, data ) {
        return new Promise( ( resolve, reject ) => {
            this.$axios.get( `/wp/v2/articles?slug=${data.params.article}&lang=${data.locale}` )
            .then( ( res ) => {
                if ( /20[0-9]/.exec(res.status) !== null) {
                    if ( res.data && res.data.length > 0 ) {
                        commit( 'setArticle', { article: data.params.article, locale: data.locale, data: res.data[ 0 ] } );
                        resolve();
                    } else {
                        reject( 'No results from API' );
                    }
                } else {
                    reject( 'Wrong status from API: ' + res.status );
                }
            } )
            .catch( ( e ) => {
                console.error( e );
                reject( e );
            } );
        } );
    },
};
