export const state = () => ({
    menus: {},
    menu_locations: {},
    main_menu: {},
    mobile_menu: {},
    footer_menu: {},
    secondary_menu: {},
    disclaimer_menu: {},
    mobile_menu_open: false,
});

export const mutations = {
    updateMenus( state, data ) {
        state.menus = data;
    },
    updateMainMenu( state, data ) {
        state.main_menu = data;
    },
    updateMobileMenu( state, data ) {
        state.mobile_menu = data;
    },
    updateFooterMenu( state, data ) {
        state.footer_menu = data;
    },
    updateSecondaryMenu( state, data ) {
        state.secondary_menu = data;
    },
    updateDisclaimerMenu( state, data ) {
        state.disclaimer_menu = data;
    },
    toggleMobileMenu( state, bool ) {
        state.mobile_menu_open = bool;
    }
};

export const actions = {
    async getMenus( { commit } ) {
        await this.$axios.get( `/menus/v1/menus` )
        .then( ( res ) => {
            if ( /20[0-9]/.exec(res.status) !== null ) {
                commit( 'updateMenus', res.data );
            } else {
                console.error( 'No menus available' );
            }
        } );
    },
    async getMainMenu( { commit } ) {
        await this.$axios.get( `/menus/v1/locations/main-menu` )
        .then( ( res ) => {
            if ( /20[0-9]/.exec(res.status) !== null ) {
                commit( 'updateMainMenu', res.data );
            } else {
                console.error( 'No Main Menu defined' );
            }
        } )
        .catch( error => error );
    },
    async getMobileMenu( { commit } ) {
        await this.$axios.get( `/menus/v1/locations/mobile-menu` )
        .then( ( res ) => {
            if ( /20[0-9]/.exec(res.status) !== null ) {
                commit( 'updateMobileMenu', res.data );
            } else {
                console.error( 'No Mobile Menu defined' );
            }
        } )
        .catch( error => error );
    },
    async getFooterMenu( { commit } ) {
        await this.$axios.get( `/menus/v1/locations/footer-menu` )
        .then( ( res ) => {
            if ( /20[0-9]/.exec(res.status) !== null ) {
                commit( 'updateFooterMenu', res.data );
            } else {
                console.error( 'No Footer Menu defined' );
            }
        } )
        .catch( error => error );
    },
    async getSecondaryMenu( { commit } ) {
        await this.$axios.get( `/menus/v1/locations/secondary-menu` )
        .then( ( res ) => {
            if ( /20[0-9]/.exec(res.status) !== null ) {
                commit( 'updateSecondaryMenu', res.data );
            } else {
                console.error( 'No Secondary Menu defined' );
            }
        } )
        .catch( error => error );
    },
    async getDisclaimerMenu( { commit } ) {
        await this.$axios.get( `/menus/v1/locations/disclaimer-menu` )
        .then( ( res ) => {
            if ( /20[0-9]/.exec(res.status) !== null ) {
                commit( 'updateDisclaimerMenu', res.data );
            } else {
                console.error( 'No Disclaimer Menu defined' );
            }
        } )
        .catch( error => error );
    },
};
