
export default function ( ctx, inject ) {
    if ( process.client ) {
        ctx.app.router.afterEach( ( to ) => {
            setTimeout( () => {
                window.dataLayer.push( {
                    routeName: to.name,
                    pageType: 'PageView',
                    pageUrl: to.fullPath,
                    pageTitle: (typeof document !== 'undefined' && document.title) || '',
                    event: 'nuxtRoute'
                } )
            }, 250 )
        } )
    }
}
