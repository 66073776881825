export const state = () => ({
    general_settings: {},
    alternateRoute: {},
});

export const mutations = {
    updateGeneralSettings( state, data ) {
        state.general_settings = data;
    },
    setAlternateRoute( state, data ) {
        state.alternateRoute = data;
    }
};

export const actions = {
    async getGeneralSettings( { commit } ) {
        await this.$axios.get( `/wp/v2/general-settings` )
        .then( ( res ) => {
            if ( /20[0-9]/.exec(res.status) !== null ) {
                commit( 'updateGeneralSettings', res.data );
            } else {
                console.error( 'No general settings available' );
            }
        } );
    },
};
