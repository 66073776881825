<template>
    <div class="cb cb--text-column block-padding--small">
        <div class="container">
            <div class="frame flex--justify-center">
                <div class="bit-xxs-12 bit-md-8">
                    <h1><span v-html="translations.error_page_title"></span></h1>
                    <div class="cb__text">
                        <p><span v-html="translations.error_page_description"></span></p>
                        <p><span v-html="translations.error_page_description_2"></span></p>
                        <p>
                            <a class="button button--icon padding-left-none" href="javascript:history.go(-1);"><span v-html="translations.error_page_link_back"></span></a>
                            <nuxt-link to="/" class="button button--icon"><span v-html="translations.error_page_link_home"></span></nuxt-link>
                        </p>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import { mapState } from 'vuex';
    import Header from "../components/header";

    export default {
        props: [ 'error' ],
        layout: 'default',
        components: { Header },
        computed: {
            ...mapState( {
                translations: function ( state ) {
                    return state.general.general_settings.translations && state.general.general_settings.translations[ this.$i18n.locale ] ? state.general.general_settings.translations[ this.$i18n.locale ] : {};
                },
            } )
        },
        head() {
            return {
                title: this.translations.error_page_title + ' – Stadermann Luiten',
            }
        },
        mounted() {
            console.log(process.env);
        }
    }
</script>
