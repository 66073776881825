import Vue from 'vue';
import VueScrollReveal from 'vue-scroll-reveal';

Vue.use( VueScrollReveal, {
    class: 'v-scroll-reveal',
    duration: 1000,
    scale: 1,
    distance: '20px',
    mobile: false,
    delay: 100,
} );
