export default function ( to, from, savedPosition ) {
    if ( to.name === 'kennisbank-theme___nl' && to.name === from.name ) {
        return { selector: '#scroll-to-archive-block', offset: { x: 0, y: 25 } }
    }

    if ( to.hash ) {
        return { selector: to.hash, offset: { x: 0, y: 25 } }
    }

    return { x: 0, y: 0 }
}
