export const state = () => ({
    teamMembers: [],
});

export const mutations = {
    setTeamMembers( state, data ) {
        state.teamMembers.push( {
            locale: data.locale,
            data: data.data,
        } );
    },
};

export const actions = {
    async getTeamMembers( { commit }, data ) {
        return new Promise( ( resolve, reject ) => {
            this.$axios.get( `/wp/v2/team-members?per_page=100&orderby=menu_order&order=asc&lang=${data.locale}` )
            .then( ( res ) => {
                if ( /20[0-9]/.exec(res.status) !== null && res.data && res.data.length > 0 ) {
                    commit( 'setTeamMembers', { locale: data.locale, data: res.data } );

                    let alternates = {};

                    _.each( res.data, member => {
                        if ( !_.isEmpty( member.alternates ) ) {
                            _.each( member.alternates, function ( a ) {
                                if ( _.isEmpty( alternates[ a.language_information.language_code ] ) ) {
                                    alternates[ a.language_information.language_code ] = [];
                                }

                                alternates[ a.language_information.language_code ].push( a );
                            } );
                        }
                    } );

                    if ( !_.isEmpty( alternates ) ) {
                        _.each( alternates, function ( a, locale ) {
                            commit( 'setTeamMembers', { locale: locale, data: a } );
                        } );
                    }

                    resolve();
                } else {
                    reject( 'Team Members - Wrong status from API: ' + res.status );
                }

            } )
            .catch( ( e ) => {
                console.error( e );
                reject( e );
            } );
        } );
    }
};
