export const state = () => ({
    themes: [],
    articleTypes: [],
    filters: [],
});

export const mutations = {
    setArticleTheme( state, data ) {
        if ( state.themes && state.themes.find( p => p.theme === data.theme ) ) {
            const theme = state.themes.find( p => p.theme === data.theme );

            theme.languages.push( {
                locale: data.locale,
                data: data.data,
            } );
        } else {
            state.themes.push( {
                theme: data.theme,
                languages: [{
                    locale: data.locale,
                    data: data.data,
                }],
            } );
        }
    },
    setArticleTypes( state, data ) {
        state.articleTypes = data.types;
    },
    setFilters( state, data ) {
        if ( !state.filters.find( p => p.theme === data.theme ) ) {
            state.filters.push( {
                theme: data.theme,
                data: data.data
            } );
        }
    }
};

export const actions = {
    async getArticleTypes( { commit }, data ) {
        return new Promise( ( resolve, reject ) => {
            this.$axios.get( `/wp/v2/article-types?lang=${data.locale}` )
            .then( ( res ) => {
                if ( /20[0-9]/.exec(res.status) !== null) {
                    commit( 'setArticleTypes', { types: res.data, locale: data.locale } );
                    resolve();
                } else {
                    reject();
                }
            } )
            .catch( ( e ) => {
                console.error( e );
                reject( e );
            } );
        } );
    },
    async getArticleTheme( { commit }, data ) {
        return new Promise( ( resolve, reject ) => {
            let requestUrl = '';
            if ( data.params.theme === 'all' ) {
                requestUrl = `/wp/v2/article-theme-overview?lang=${data.locale}`;
            } else {
                requestUrl = `/wp/v2/article-theme?slug=${data.params.theme}&lang=${data.locale}`;
            }

            this.$axios.get( requestUrl )
            .then( ( res ) => {
                if ( /20[0-9]/.exec(res.status) !== null) {
                    if ( res.data && res.data.length > 0 ) {
                        commit( 'setArticleTheme', { theme: data.params.theme, locale: data.locale, data: res.data[ 0 ] } );
                        resolve();
                    } else {
                        console.error( 'No results from API' );
                        reject( 'No results from API' );
                    }
                } else {
                    console.error( 'Wrong status from API: ' + res.status );
                    reject( 'Wrong status from API: ' + res.status );
                }
            } )
            .catch( ( e ) => {
                console.error( e );
                reject( e );
            } );
        } );
    },
    async getFilters( { commit }, data ) {
        let requestUrl = '';
        if ( data.params && data.params.theme ) {
            requestUrl = `/wp/v2/filters?main_theme=${data.params.theme}`;
        } else {
            requestUrl = `/wp/v2/filters`;
        }
        await this.$axios.get( requestUrl )
        .then( ( res ) => {
            if ( /20[0-9]/.exec(res.status) !== null) {
                if ( res.data ) {
                    commit( 'setFilters', { theme: data.params.theme, data: res.data } );
                } else {
                    console.error( { statusCode: 404, message: 'Filters: res but no data' } );
                }
            } else {
                console.error( { statusCode: 404, message: 'Filters: no res' } );
            }
        } )
        .catch( ( e ) => {
            console.error( e );
            console.error( { statusCode: 404, message: 'Filters: catch error' } );
        } );
    }
};
