<template>
    <div>
        <nav v-if="primary_menu && primary_menu.items" class="footer-menu footer-menu--primary" role="navigation" aria-label="Footer menu">
            <ul :id="primary_menu.term_id" class="footer-menu__wrapper" :class="'menu--' + primary_menu.slug">
                <li v-for="item in primary_menu.items" class="footer-menu__item" :class="[(item.child_items ? 'menu-item-has-children' : ''), (addMenuClasses(item))]">
                    <nuxt-link :to="item.url" :title="item.title" :target="item.target"><span v-html="item.title"></span></nuxt-link>
                </li>
            </ul>
        </nav>
        <nav v-if="secondary_menu && secondary_menu.items" class="footer-menu footer-menu--secondary" role="navigation" aria-label="Footer menu secondary">
            <ul :id="secondary_menu.term_id" class="footer-menu__wrapper" :class="'menu--' + secondary_menu.slug">
                <li v-for="item in secondary_menu.items" class="footer-menu__item" :class="[(item.child_items ? 'menu-item-has-children' : ''), (addMenuClasses(item))]">
                    <nuxt-link :to="item.url" :title="item.title" :target="item.target"><span v-html="item.title"></span></nuxt-link>
                </li>
            </ul>
        </nav>
    </div>
</template>

<script>
    import { mapState } from 'vuex';

    export default {
        name: 'MenuFooter',
        computed: {
            ...mapState( {
                primary_menu: function ( state ) {
                    const object = state.menus.footer_menu.find( d => d.locale === this.$i18n.locale );

                    if ( object && object.menu_items ) {
                        return object.menu_items;
                    }
                },
                secondary_menu: function ( state ) {
                    const object = state.menus.secondary_menu.find( d => d.locale === this.$i18n.locale );

                    if ( object && object.menu_items ) {
                        return object.menu_items;
                    }
                },
            } ),
        },
        methods: {
            addMenuClasses( item ) {
                if ( item.classes ) {
                    return item.classes.join( ' ' );
                }
            },
        }
    }
</script>

<style scoped>

</style>
