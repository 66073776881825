export default async ( { app, route, store, req, res, redirect } ) => {
    
    // beforeLanguageSwitch called right before setting a new locale
    app.i18n.beforeLanguageSwitch = ( oldLocale, newLocale ) => {
        if ( !store.state.frontpage.data.find( d => d.locale === newLocale ) ) {
            const data = {
                locale: newLocale,
            };
            store.dispatch( 'frontpage/get', data );
        }
    }
}
