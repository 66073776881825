<template>
    <div v-if="notification && notification.show_notification && notification.content" class="site-notification background--blue-dark text--white">
        <div class="container">
            <div class="frame">
                <div class="bit-xxs-12 site-notification__inner text-center">
                    <i class=" site-notification__icon text--green text--base fas fa-info-circle"></i>
                    <span v-html="notification.content"></span>
                    <app-link v-if="notification.link" :to="notification.link.url" :target="notification.link.target" class="site-notification__link"><span v-html="notification.link.title"></span></app-link>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import { mapState } from 'vuex';
    import AppLink from "./app-link";

    export default {
        name: 'SiteNotification',
        components: { AppLink },
        computed: {
            ...mapState( {
                notification: function ( state ) {
                    return state.general.general_settings.notification[ this.$i18n.locale ];
                },
            } ),
        },
    }
</script>
