import Vue from 'vue';

Vue.mixin( {
    methods: {
        buildSeoTitle( obj ) {
            return obj && obj.title ? this.htmlDecode( obj.title ) : "Stadermann Luiten";
        },
        buildSeoMetaData( obj, i18nSeoObj ) {
            let seoData = [];
            
            if ( obj ) {
                
                seoData.push( { hid: 'title', id: 'title', name: 'title', content: obj.title ? this.htmlDecode( obj.title ) : "Stadermann Luiten" } );
                
                if ( obj.robots ) {
                    seoData.push( { hid: 'robots', id: 'robots', name: 'robots', content: obj.robots } );
                }
                if ( obj.metadesc ) {
                    seoData.push( { hid: 'description', id: 'description', name: 'description', content: obj.metadesc } );
                }
                
                if ( obj.open_graph_enabled ) {
                    if ( obj.open_graph_title ) {
                        seoData.push( { hid: 'og-title', id: 'og:title', property: 'og:title', content: this.htmlDecode( obj.open_graph_title ) } );
                    }
                    if ( obj.open_graph_description ) {
                        seoData.push( { hid: 'og-description', id: 'og:description', property: 'og:description', content: obj.open_graph_description } );
                    }
                    if ( obj.open_graph_image ) {
                        seoData.push( { hid: 'og-image', id: 'og:image', property: 'og:image', content: obj.open_graph_image } );
                        seoData.push( { hid: 'og-image-width', id: 'og:image:width', property: 'og:image:width', content: obj.open_graph_image_width } );
                        seoData.push( { hid: 'og-image-height', id: 'og:image:height', property: 'og:image:height', content: obj.open_graph_image_height } );
                    }
                    if ( obj.open_graph_type ) {
                        seoData.push( { hid: 'og-type', id: 'og:type', property: 'og:type', content: obj.open_graph_type } );
                    }
                    if ( obj.locale ) {
                        seoData.push( { hid: 'og-locale', id: 'og:locale', property: 'og:locale', content: obj.locale } );
                    }
                    if ( obj.url ) {
                        seoData.push( { hid: 'og-url', id: 'og:url', property: 'og:url', content: obj.url } );
                    }
                    
                    if ( obj.site_name ) {
                        seoData.push( { hid: 'og-site-name', id: 'og:site_name', property: 'og:site_name', content: obj.site_name } );
                    }
                }
                
                if ( obj.twitter_card ) {
                    seoData.push( { hid: 'twitter-card', id: 'twitter:card', name: 'twitter:card', content: obj.twitter_card } );
                }
                if ( obj.twitter_title ) {
                    seoData.push( { hid: 'twitter-title', id: 'twitter:title', name: 'twitter:title', content: obj.twitter_title } );
                }
                if ( obj.twitter_description ) {
                    seoData.push( { hid: 'twitter-description', id: 'twitter:description', name: 'twitter:description', content: obj.twitter_description } );
                }
                if ( obj.twitter_image ) {
                    seoData.push( { hid: 'twitter-image', id: 'twitter:image', name: 'twitter:image', content: obj.twitter_image } );
                }
                if ( obj.twitter_creator ) {
                    seoData.push( { hid: 'twitter-creator', id: 'twitter:creator', name: 'twitter:creator', content: obj.twitter_creator } );
                }
                if ( obj.twitter_site ) {
                    seoData.push( { hid: 'twitter-site', id: 'twitter:site', name: 'twitter:site', content: obj.twitter_site } );
                }
                if ( obj.published_time ) {
                    seoData.push( { hid: 'article-published-time', id: 'article:published_time', property: 'article:published_time', content: obj.published_time } );
                }
                if ( obj.modified_time ) {
                    seoData.push( { hid: 'article-modified-time', id: 'article:modified_time', property: 'article:modified_time', content: obj.modified_time } );
                }
                
            }
            
            seoData.push( ...i18nSeoObj.meta );
            
            return seoData;
        },
        buildSeoLinkData( seoObj, i18nSeoObj ) {
            return [
                ...i18nSeoObj.link
            ];
        },
        buildSeoSchema( obj ) {
            return {
                type: 'application/ld+json',
                json: obj && obj.schema,
            }
        }
    }
} );
