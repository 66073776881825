<template>
    <nav class="mobile-menu" :class="mobile_menu_open ? 'mobile-menu--open' : ''" data-mobile-menu data-mobile-menu-type="overlay" role="navigation" aria-label="Mobiel Menu">
        <LangSwitcher/>
        <a href="javascript:;" class="menu-trigger mobile-menu__trigger" :class="{'menu-trigger--open': mobile_menu_open, 'menu-trigger--green': backgroundColor === 'white' }" data-menu-trigger @click.prevent="toggleMenu">
            <i class="fal fa-times menu-trigger__icon-bar-close "></i>
        </a>
        <div v-if="menu" class="mobile-menu__content">
            <div class="mobile-menu__content__inner">
                <ul :id="menu.term_id" class="menu menu--mobile" :class="'menu--' + menu.slug" v-if="menu.items">
                    <li v-for="item in menu.items" class="menu-item" :class="[(item.child_items ? 'menu-item-has-children' : ''), (addMenuClasses(item))]">
                        <nuxt-link :to="item.url" :title="item.title" :target="item.target" @click.native="toggleMenu"><span v-html="item.title"></span></nuxt-link>
                        <ul class="sub-menu" v-if="item.child_items">
                            <li v-for="child_item in item.child_items" class="menu-item">
                                <nuxt-link :to="child_item.url" :title="child_item.title" :target="item.target" @click.native="toggleMenu"><h2><span v-html="child_item.title"></span></h2></nuxt-link>
                            </li>
                        </ul>
                    </li>
                </ul>
                <ul v-if="secondary_menu && secondary_menu.items" :id="secondary_menu.term_id" class="menu menu--mobile-secondary" :class="'menu--' + secondary_menu.slug">
                <li v-for="item in secondary_menu.items" class="menu__item" :class="[(item.child_items ? 'menu-item-has-children' : ''), (addMenuClasses(item))]">
                    <nuxt-link :to="item.url" :title="item.title" :target="item.target"><span v-html="item.title"></span></nuxt-link>
                </li>
            </ul>
            </div>
            <footer class="mobile-menu__footer mobile-menu-footer">
                <p class="mobile-menu__footer__contact-info">
                    <span v-if="footer.contact_street"><span v-html="footer.contact_street"></span><br/></span>
                    <span v-if="footer.contact_postbus"><span v-html="footer.contact_postbus"></span><br/></span>
                    <span v-if="footer.contact_zip"><span v-html="footer.contact_zip"></span></span>
                    <span v-if="footer.contact_city"><span v-html="footer.contact_city"></span></span>
                </p>
                <ul v-if="disclaimer_menu && disclaimer_menu.items" class="menu menu--mobile-disclaimer" :class="'menu--' + disclaimer_menu.slug">
                    <li v-for="item in disclaimer_menu.items" class="menu__item" :class="[(item.child_items ? 'menu-item-has-children' : ''), (addMenuClasses(item))]">
                        <nuxt-link :to="item.url" :title="item.title" :target="item.target"><span v-html="item.title"></span></nuxt-link>
                    </li>
                </ul>
            </footer>
        </div>
    </nav>
</template>

<script>
    import { mapState } from 'vuex';
    import LangSwitcher from "./lang-switcher";

    export default {
        name: 'MobileMenu',
        components: {
            LangSwitcher,
        },
        props: {
            backgroundColor: {
                type: String,
                default: 'white',
                validator: function ( value ) {
                    return [ 'white', 'transparent', 'blue', 'gray' ].indexOf( value ) !== -1;
                }
            },
        },
        computed: {
            ...mapState( {
                menu: function ( state ) {
                    const object = state.menus.main_menu.find( d => d.locale === this.$i18n.locale );

                    if ( object && object.menu_items ) {
                        return object.menu_items;
                    }
                },
                mobile_menu_open: state => state.menus.mobile_menu_open,
                secondary_menu: function ( state ) {
                    const object = state.menus.secondary_menu.find( d => d.locale === this.$i18n.locale );

                    if ( object && object.menu_items ) {
                        return object.menu_items;
                    }
                },
                footer: function ( state ) {
                    return state.general.general_settings.footer[ this.$i18n.locale ];
                },
                disclaimer_menu: function ( state ) {
                    const object = state.menus.disclaimer_menu.find( d => d.locale === this.$i18n.locale );

                    if ( object && object.menu_items ) {
                        return object.menu_items;
                    }
                },
            } ),
        },
        methods: {
            toggleMenu() {
                this.$store.commit( 'menus/toggleMobileMenu', !this.mobile_menu_open );
            },
            addMenuClasses( item ) {
                if ( item.classes ) {
                    return item.classes.join( ' ' );
                }
            },
        }
    }
</script>

<style scoped>

</style>
