<template>
    <div class="page-container">
        <SiteNotification/>
        <Header/>
        <main>
            <nuxt/>
        </main>
        <Footer/>
        <span v-if="cookie_consent" v-html="cookie_consent"></span>
    </div>
</template>


<script>
import Header from '~/components/header.vue';
import Footer from '~/components/footer.vue';
import { mapState } from 'vuex';
import SiteNotification from "../components/site-notification";

export default {
    components: {
        SiteNotification,
        Header,
        Footer
    },
    computed: {
        ...mapState( {
            cookie_consent: state => state.general.general_settings.cookie_consent,
        } ),
    },
}
</script>
