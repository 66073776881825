<template>
    <footer class="footer">
        <section class="block-padding block-padding--medium block-padding--small-bottom">
            <div class="container">
                <div class="frame">
                    <div class="bit-xxs-12 bit-sm-6 bit-md-4 footer__description__container padding-bottom-none">
                        <h2 class="site__brand">
                            <nuxt-link :to="localePath('index')" :title="blogTitle">
                                <img :src="require( `@/assets/images/logo-stadermann-luiten-kleur.png` )" :alt="blogTitle" class="img-responsive"/>
                            </nuxt-link>
                        </h2>
                        <div class="footer__description">
                            <span v-html="footer.description"></span>
                        </div>
                    </div>
                    <div class="bit-xxs-12 bit-sm-3 bit-md-offset-2 padding-bottom-none">
                        <h3 class="footer__title"><span v-html="translations.menu"></span></h3>
                        <MenuFooter/>
                    </div>
                    <div class="bit-xxs-12 bit-sm-3 padding-bottom-none">
                        <h3 class="footer__title"><span v-html="translations.contact"></span></h3>
                        <p class="footer__contact-info">
                            <span v-if="footer.contact_phone"><a :href="'tel:' + footer.contact_phone"><span v-html="footer.contact_phone"></span></a><br/></span>
                            <span v-if="footer.contact_email"><a :href="'mailto:' + footer.contact_email"><span v-html="footer.contact_email"></span></a><br/></span>
                        </p>
                        <p class="footer__contact-info footer__contact-info--small">
                            <span v-if="footer.contact_street"><span v-html="footer.contact_street"></span><br/></span>
                            <span v-if="footer.contact_postbus"><span v-html="footer.contact_postbus"></span><br/></span>
                            <span v-if="footer.contact_zip"><span v-html="footer.contact_zip"></span></span>
                            <span v-if="footer.contact_city"><span v-html="footer.contact_city"></span></span>
                        </p>
                    </div>
                </div>
            </div>
        </section>

        <section class="footer__disclaimer">
            <div class="container">
                <div class="frame">
                    <div class="bit-xxs-12 bit-sm-6">
                        &copy; {{this.currentYear}} <span v-html="translations.copyright"></span>
                    </div>
                    <div class="bit-xxs-12 bit-sm-6">
                        <nav v-if="disclaimer_menu && disclaimer_menu.items" class="footer-menu footer-menu--disclaimer" role="navigation" aria-label="Footer menu secondary">
                            <ul :id="disclaimer_menu.term_id" class="footer-menu__wrapper" :class="'menu--' + disclaimer_menu.slug">
                                <li v-for="item in disclaimer_menu.items" class="footer-menu__item" :class="[(item.child_items ? 'menu-item-has-children' : ''), (addMenuClasses(item))]">
                                    <nuxt-link :to="item.url" :title="item.title" :target="item.target"><span v-html="item.title"></span></nuxt-link>
                                </li>
                            </ul>
                        </nav>
                    </div>
                </div>
            </div>
        </section>
    </footer>
</template>

<script>
    import { mapState } from 'vuex';
    import MenuFooter from "./menu-footer";

    export default {
        name: 'Footer',
        components: { MenuFooter },
        computed: {
            ...mapState( {
                footer: function ( state ) {
                    return state.general.general_settings.footer[ this.$i18n.locale ];
                },
                blogTitle: ( state ) => {
                    return state.general.general_settings && state.general.general_settings.blog_title ? state.general.general_settings.blog_title : 'Stadermann Luiten Advocaten';
                },
                translations: function ( state ) {
                    return state.general.general_settings.translations[ this.$i18n.locale ];
                },
                disclaimer_menu: function ( state ) {
                    const object = state.menus.disclaimer_menu.find( d => d.locale === this.$i18n.locale );

                    if ( object && object.menu_items ) {
                        return object.menu_items;
                    }
                },
            } ),
            currentYear() {
                let date = new Date();

                return date.getFullYear();
            }
        },
        methods: {
            addMenuClasses( item ) {
                if ( item.classes ) {
                    return item.classes.join( ' ' );
                }
            },
        },
    }
</script>
